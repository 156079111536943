import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import SlickSlider from "../components/SlickSlider"
// import Search from "../components/resourceLibrary/ResourceLibrarySearch"
// import ResourceLibraryItems from "../components/resourceLibrary/ResourceLibraryItems"

const ResourceLibrary = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={"Resource Library"}
        description={
          "EnerBank USA is America's home improvement lender of choice. We help contractors grow their business and homeowners have the home of their dreams."
        }
      />
      
      <section className="resource-library mb-5 pt-3 bg-light-gray">
        <div className="container ">
          <h1 className="text-center mt-5 resource-library-header">
            Resource Library
          </h1>
          <h3 className="text-center border-heading mb-5">
            <span>Recently Added</span>
          </h3>
          <div className="row mt-5">
            {data.allWhitePapersJson.nodes.map(
              ({ link, image, title, text }) => (
                <div className="col-md-6 col-lg-4 mb-4" key={link}>
                  <div className="card d-flex flex-row p-3">
                    <div className="col-4 p-0 library-image">
                      <GatsbyImage
                        imgClassName="img-fluid rounded-start"
                        image={getImage(image)}
                        alt={title}
                      />
                    </div>
                    <div className="card-body col-8 p-0 pl-3">
                      <h3 className="card-title h5 mb-4 clamped-text white-paper-title">
                        {title}
                      </h3>
                      <p className="clamped-text">{text}</p>
                      <Link
                        to={link}
                        className="btn btn-green btn mt-auto ml-auto py-2 px-3"
                      >
                        Get It Now
                      </Link>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>


          {/* <Search /> */}

          {/* <ResourceLibraryItems items={data.allItemsJson.nodes} /> */}
        </div>
      </section>
    </Layout>
  )
}

export default ResourceLibrary

export const query = graphql`
  query ResourceLibraryQuery {
    allWhitePapersJson {
      nodes {
        link
        text
        title
        image {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
    allItemsJson {
      nodes {
        id
        title
        image {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
